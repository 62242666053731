@import '../variables';
@import '../mixins';

.wrapper {
  background-color: #d8d8d8;
  height: 50px;
  transition: height 0.3s ease-in-out;
}

.search-wrapper {
  background-color: #d8d8d8;
  height: 100px;
  transition: height 0.3s ease-in-out;
}

.row {
  height: 100%;
  @include displayFlex($display: flex, $direction: row, $align-items: center);
}

.white-wrapper {
  background-color: $white;
  height: 50px;
}
