@import '../variables';
@import '../mixins';

.button-container {
  @include displayFlex($display: flex, $align-items: center, $direction: column);
}

.forgot-password {
  margin: 15px 0;
  border-bottom: 1px solid $grey;
  padding-bottom: 3px;
  cursor: pointer;
}

.login-wrapper {
  @media only screen and (min-width: 768px) {
    @include displayFlex($display: flex, $align-items: center, $direction: column);
    * {
      min-width: 50%;
    }
  }
}
