@import '../variables';
@import '../mixins';

.row {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  width: stretch;
  .title {
    @include font($weight: bold, $color: $black, $decoration: none);
    font-weight: bold;
    cursor: pointer;
    word-break: break-all;
    width: stretch;
    margin-left: 5px;
  }
  .logo-container {
    @include size($width: 30px, $height: 30px);
    @include displayFlex($display: flex, $justify-content: center, $align-items: center);

    .logo {
      @include size($width: 25px, $height: 25px);
      border-radius: 50%;
      color: $white;
      border: 1px solid $primary;
      &-selected {
        background-color: $primary;
      }
      &-unselected {
        background-color: white;
      }
    }
  }
}
.line {
  width: 100%;
  height: 1px;
  background-color: $border-grey;
  margin: 10px 0;
}
