@import '../variables';
@import '../mixins';

.v-line {
  @include size($width: 1px, $height: 100%);
  background-color: $border-grey;
}

.wrapper {
  background-color: $white;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: $shadow;
  height: 100%;
}

.row {
  width: 100%;
  @include displayFlex($display: flex, $direction: row);
}
.info-row {
  width: 100%;
  @include displayFlex($display: flex, $direction: row);
  padding: 5px 0;
}
.half {
  min-width: 35%;
  margin-right: 5px;
}
.collapse-container {
  width: 100%;
}

.image {
  @include size($width: 100px, $height: 100px);
  object-fit: contain;
  padding: 2px;
  &-container {
    @include size($width: 100px, $height: 100px);
    margin-right: 15px;
  }
}
.title {
  margin-right: 10px;
  @include font($weight: bold, $text-transform: uppercase);
}

.price {
  @include font($weight: bold, $text-transform: uppercase, $color: $primary);
  white-space: nowrap;
}
.user {
  opacity: 0.5;
}
.content {
  padding-top: 10px;
}

.btn-container {
  @include displayFlex($display: flex, $justify-content: flex-end);
}

.time {
  &-icon {
    color: $primary;
    margin-right: 10px;
  }

  &-description {
    opacity: 0.5;
  }
}

.sync {
  &-icon {
    color: $blue;
    margin-right: 10px;
  }

  &-description {
    opacity: 0.5;
  }
}

.text-container {
  @include displayFlex($display: flex, $direction: column, $align-items: stretch, $justify-content: space-between);
}

.line {
  margin: 10px 0;
  border-bottom: 1px solid $grey;
  width: 100%;
  &-big-padding {
    margin: 20px 0;
    border-bottom: 1px solid $grey;
    width: 100%;
  }
}

.bubble {
  @include size($width: 22px, $height: 22px);
  border-radius: 50%;
  background-color: $dark-grey;
  &-container {
    @include displayFlex($display: flex, $direction: column, $align-items: center);
    margin-right: 10px;
  }
}
.long-line {
  background-color: $border-grey;
  width: 1px;
  height: 100%;
  margin: 0;
  flex: 2;
}
.short-line {
  background-color: $border-grey;
  width: 1px;
  height: 12px;
  margin: 0;
}
.additional-info {
  opacity: 0.6;
  margin-left: 5px;
}
.coupon {
  &-button {
    margin-right: 16px;
  }

  &-modal {
    &-wrapper {
      @include size($width: 100%, $height: 100%);
      @include displayFlex($display: flex, $direction: column, $align-items: center, $justify-content: space-around);
      text-align: center;
    }
    &-title {
      font-weight: bold;
    }
    &-content {
      white-space: initial;
    }
    &-button-container {
      width: 100%;
      @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-around);
      gap: 8px;
    }
  }
}
