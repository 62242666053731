@import '../variables';
@import '../mixins';

.input-container {
  @include displayFlex($display: flex, $direction: column);
  margin: 15px 0;

  input {
    padding: 0 10px;
  }
}

.text {
  margin: 5px 0;
}

.error-message {
  color: $secondary;
  margin-bottom: 5px;
}
