@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: row);
  overflow-wrap: anywhere;
  cursor: pointer;

  .green-bubble {
    @include size($width: 40px, $height: 40px);
    border-radius: 50%;
    background-color: $blue;
    margin: 5px 2px 1px 5px;
    &:hover {
      cursor: pointer;
    }
    &-container {
      @include displayFlex($display: flex, $direction: column, $align-items: center);
      margin-right: 10px;
    }
    &-out {
      @include size($width: 50px, $height: 50px);
      border-radius: 50%;
      background-color: $border-grey;
    }
  }

  .bubble {
    @include size($width: 40px, $height: 40px);
    border-radius: 50%;
    background-color: $dark-grey;
    margin: 5px 2px 1px 5px;
    &:hover {
      cursor: pointer;
    }
    &-container {
      @include displayFlex($display: flex, $direction: column, $align-items: center);
      margin-right: 10px;
    }
    &-out {
      @include size($width: 50px, $height: 50px);
      border-radius: 50%;
      background-color: $border-grey;
    }
    &-out-red {
      @include size($width: 50px, $height: 50px);
      border-radius: 50%;
      background-color: #d24444;
    }
  }
  .right {
    width: 100%;
  }
  .long-line {
    background-color: $border-grey;
    width: 1px;
    height: 100%;
    margin: 0;
    flex: 2;
  }
  .short-line {
    background-color: $border-grey;
    width: 1px;
    height: 5px;
    margin: 0;
  }
  .red-icon {
    color: $secondary;
    margin-right: 12px;
    margin-left: 1px;
  }
  .logo {
    @include size($width: 20px, $height: 20px);
    color: $dark-grey;
    margin-right: 10px;
  }
  .lighter {
    opacity: 0.6;
    padding-right: 8px;
  }
  .image {
    @include displayFlex($display: flex, $justify-content: center, $align-items: center);
    @include size($width: 100px, $height: 100px);
    object-fit: contain;
    border: 1px solid $border-grey;
    border-radius: 10px;
    padding: 2px;
    position: relative;

    &-wrapper {
      position: relative;
    }
    &-row {
      @include displayFlex($display: flex, $direction: row, $align-items: center);
      cursor: pointer;
    }
    &-icon {
      @include size($width: stretch, $height: auto);
      padding: 25px;
      background-color: $dark-grey;
      color: $white;
      border-radius: 10px;
      cursor: pointer;
    }
  }
  .box {
    margin: 6px 0;
    border-radius: 10px;
    padding: 10px;
    background-color: $border-grey;
    cursor: pointer;
  }
  .title {
    @include font($weight: bold, $size: 18px, $color: $primary);
    margin-top: 16px;
  }
  .subtitle {
    @include font($size: 18px);
  }
  .description-container {
    @include displayFlex($display: flex, $direction: column, $justify-content: space-between);
    @include size($min-width: 100px, $min-height: 100px);
    flex: 1;
  }
  .date {
    &-section {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 8px;
      margin-bottom: 8px;
      margin-top: 16px;
    }
    &-text {
      @include font($weight: normal);
    }
    &-text-bold {
      @include font($weight: bold);
    }
    &-icon {
      margin: 0 8px;
      @include size($width: 22px, $height: 22px);
    }
  }

  .link {
    &-icon {
      @include size($width: 20px, $height: 20px);
      color: $blue;
      margin: 0 10px;
      stroke-width: 0.8;
    }

    &-container {
      @include displayFlex($display: flex, $direction: row, $justify-content: flex-end);
    }
  }

  .flex {
    @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
  }

  .info {
    &-red {
      margin: 0 8px;
      @include size($width: 22px, $height: 22px);
      path {
        fill: #d24444;
      }
    }
  }

  .red-text {
    color: #d24444;
  }
}
