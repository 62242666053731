@import '../../variables';
@import '../../mixins';

.info {
  &-container {
    @include displayFlex($display: flex, $align-items: center, $direction: row);
    margin-top: 12px;
  }
  &-image {
    @include size($width: 21px, $height: 21px);
    margin: 0 5px 2px 2px;
    color: #1941a3;
  }
  &-description {
    @include font($size: 14px, $color: #1941a3, $weight: 400);
  }
}
