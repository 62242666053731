@import '../variables';
@import '../mixins';

.attribute {
  width: 80px;
  &-primary {
    color: $primary;
  }
  &-indented {
    margin-left: 15px;
    width: 80px;
  }
}

.row {
  margin: 15px 0;
  @include displayFlex($display: flex, $direction: column, $align-items: start);

  &-center {
    margin: 30px 0 10px 0;
    @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: center);
  }
}

.note {
  @include size($width: stretch, $height: auto);
  border: 1px solid $border-grey;
  border-radius: 10px;
  padding: 10px;
  min-height: 20px;
}

.text {
  min-width: 25px;
}

.line {
  margin: 0;
  height: 1px;
  background-color: $grey;
}

.bolded {
  font-weight: bold;
}

.placeholder {
  color: $grey;
}

.gray-card {
  background-color: $grey;
  padding: 0 12px 6px 12px;
  border-radius: 12px;
  margin-top: 32px;
}

.white-input {
  background-color: white;
  width: 90% !important;
}

.label-margin {
  min-width: 80px;
}

.title-margin {
  margin-top: 24px;
}

.input-error {
  color: $secondary;
  margin-top: 8px;
}

.icon {
  @include size($width: 25px, $height: 25px);
  color: $secondary;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
}

.email {
  &-section {
    padding-bottom: 40px;
  }

  &-row {
    @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
    margin: 18px 0;
  }
}
