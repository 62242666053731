@import '../variables';
@import '../mixins';

.input {
  width: 95%;
  margin: 10px 0;
  padding: 0 10px;
}

.row {
  @include displayFlex($display: flex, $align-items: center, $direction: row, $justify-content: center);
  margin: 25px 0;
}

.error-text {
  color: $secondary;
}

.orange {
  color: $primary;
  margin-bottom: 10px;
}

.radio-button-text {
  margin-right: 25px;
}

.green-text {
  color: $green;
  font-weight: bold;
  margin-left: 5px;
}

.flex-container {
  @include displayFlex($display: flex, $align-items: center, $direction: row, $justify-content: left);
}

.info {
  &-container {
    @include displayFlex($display: flex, $align-items: center, $direction: row);
    margin-bottom: 10px;
  }
  &-image {
    @include size($width: 21px, $height: 21px);
    margin: 0 5px 2px 0;
    color: #1941a3;
  }
  &-description {
    @include font($size: 14px, $color: #1941a3, $weight: 400);
  }
}

.indented {
  margin-left: 10px;
}

.size-row {
  @include displayFlex($display: flex, $align-items: center, $direction: row, $justify-content: center);
  input {
    margin-right: 24px;
  }
}
