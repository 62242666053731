@import '../variables';

@mixin size($width: null, $height: null, $min-width: null, $min-height: null, $max-width: null, $max-height: null) {
  width: $width;
  height: $height;
  min-width: $min-width;
  min-height: $min-height;
  max-width: $max-width;
  max-height: $max-height;
}

@mixin displayFlex(
  $display: null,
  $justify-content: null,
  $align-items: null,
  $direction: null,
  $wrap: null,
  $grow: null
) {
  display: $display;
  flex-direction: $direction;
  flex-wrap: $wrap;
  flex-grow: $grow;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin position($position: null, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin font(
  $size: null,
  $weight: null,
  $transform: null,
  $color: null,
  $line-height: null,
  $text-align: null,
  $text-overflow: null,
  $family: null,
  $font-weight: null,
  $word-spacing: null,
  $word-break: null,
  $text-transform: null,
  $decoration: null
) {
  color: $color;
  line-height: $line-height;
  text-align: $text-align;
  text-overflow: $text-overflow;
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  word-spacing: $word-spacing;
  text-transform: $transform;
  font-weight: $font-weight;
  word-break: $word-break;
  text-transform: $text-transform;
  text-decoration: $decoration;
}

@mixin placeholderColor($color: $primary, $opacity: null) {
  ::-webkit-input-placeholder {
    /* Edge */
    color: $color;
    opacity: $opacity;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color;
    opacity: $opacity;
  }

  ::placeholder {
    color: $color;
    opacity: $opacity;
  }
}

@mixin clearOutline() {
  &:active,
  &:hover,
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

@mixin clickEffect() {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }

  &:active,
  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

@mixin blur($backdrop-filter: null, $background-color: null) {
  backdrop-filter: blur($backdrop-filter);
  background-color: $background-color;
}

@mixin backgroud-image($background-image: null, $repeat: null, $position: null) {
  background: url($background-image) $repeat $position;
}
