@import '../variables';
@import '../mixins';

.wrapper {
  margin: 24px 0;
}

.crop-button {
  margin-top: 12px;
  margin-right: 12px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
