@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
  @include size($width: 100%, $height: 100%);
}
.wrapper-left {
  @include size($width: 100%, $height: 100%);
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: flex-end);
  .icon {
    color: $blue;
    @include size($width: 20px, $height: 20px);
    margin-left: 10px;
  }
  .line {
    width: 2px;
    height: 60%;
    background-color: $dark-grey;
    margin: 0 10px;
  }
}

.flex {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: flex-end);
}
.link {
  cursor: pointer;
}
