@import '../variables';
@import '../mixins';

.wrapper {
  min-height: 130px;
  background-image: linear-gradient($border-grey 15%, white 80%);
  margin-bottom: 10px;
  margin-top: 20px;
  overflow-wrap: anywhere;
}
.client {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: flex-end);
  padding-top: 5px;
}
.logo {
  object-fit: contain;
  @include size($width: 100px, $height: 100px);
  margin: 15px;
  border: 1px solid $border-grey;
  border-radius: 10px;
}
.data-container {
  height: 100%;
  @include displayFlex($display: flex, $direction: column, $align-items: stretch, $justify-content: space-between);
  min-width: 0;
}
.text-container {
  padding-top: 5px;
}
.name {
  font-size: 30px;
}
.country {
  color: $primary;
}
.email-container {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  padding-top: 10px;
  min-width: 0;
}
.icon {
  @include size($width: 20px, $height: 20px);
  margin-right: 5px;
  color: $blue;
  border: 1px solid $blue;
  border-radius: 50%;
  padding: 1px;
  min-width: 20px;
  min-height: 20px;
}

.inp + label {
  color: $black;
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
}

.image {
  @include displayFlex($display: flex, $justify-content: center, $align-items: center);
  @include size($width: 100px, $height: 100px);
  object-fit: contain;
  border: 1px solid $border-grey;
  border-radius: 10px;
  position: relative;
  &-row {
    @include displayFlex($display: flex, $direction: row, $align-items: center);
    cursor: pointer;
  }
  &-icon {
    @include size($width: stretch, $height: auto);
    padding: 25px;
    background-color: $dark-grey;
    color: $white;
    border-radius: 10px;
    cursor: pointer;
  }
}

.edit-icon {
  @include size($width: 20px, $height: 20px);
  @include position($position: absolute, $bottom: -5px, $right: -5px);
  color: $white;
  padding: 10px;
  border-radius: 50%;
  background-color: $grey;
}

.image-container {
  margin-right: 23px;
}

.section {
  @include displayFlex($display: flex, $align-items: center);
  height: 100%;
}
.email {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-type {
  padding: 8px 0 8px 0px;
}
