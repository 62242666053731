@import 'mixins';

.general-small-padding {
  padding: 0 30px;
  @include media-breakpoint-down(xl) {
    padding: 0 30px;
  }

  @include media-breakpoint-down(lg) {
    padding: 0 30px;
  }

  @include media-breakpoint-down(md) {
    padding: 0 30px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 30px;
  }

  @include media-breakpoint-down(xm) {
    padding: 0 30px;
  }

  @include media-breakpoint-down(xs) {
    padding: 0 20px;
  }
}
