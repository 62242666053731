@import '../variables';
@import '../mixins';

.margin {
  margin: 20px 0;
}

.content {
  margin: 10px 0;
}
