@import '../variables';
@import '../mixins';

.wrapper {
  padding-top: 5px;
}

.email-info {
  opacity: 0.5;
}

.info {
  &-container {
    @include displayFlex($display: flex, $align-items: center, $direction: row);
    margin-bottom: 10px;
    margin-top: 10px;
  }
  &-image {
    @include size($width: 21px, $height: 21px);
    margin: 0 5px 2px 0;
    color: #1941a3;
  }
  &-description {
    @include font($size: 14px, $color: #1941a3, $weight: 400);
  }
}

.indented {
  margin-left: 10px;
}
.submit {
  &-container {
    @include displayFlex($display: flex, $justify-content: center);
    width: 100%;
    margin-top: 50px;
    margin-bottom: 25px;
  }

  background-color: $blue !important;
}

.subtitle {
  @include font($weight: 700);
  margin-bottom: 20px;
}

.line {
  margin: 20px 0;
  border-bottom: 1px solid $grey;
  width: 100%;
}

.country-dropdown-container {
  margin: 15px 0;
}
.row {
  @include displayFlex($display: flex, $direction: row);
}
.option {
  margin: 0 5px 0 15px;
}

.text-wrap {
  word-break: break-all;
}
