@import '../variables';
@import '../mixins';

.wrapper {
  padding-top: 30px;
}

.bubble {
  padding: 5px 10px;
  border: 1px solid $border-grey;
  border-radius: 10px;
  margin-right: 10px;
}

.line {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
  margin: 10px 0;
  width: 80%;
  .no-margin {
    margin-right: 0;
  }
}

.center-text {
  text-align: center;
}

.title {
  font-weight: bold;
}

.btn {
  margin: 5px 30px;
  &-container {
    margin: 20px 0;
    @include displayFlex($display: flex, $direction: column);
  }
}

.indent {
  margin-left: 10px;
  p {
    margin-bottom: 8px;
  }
  .price {
    margin-bottom: 20px;
  }
}

.horizontal-line {
  margin: 0;
  margin-top: 5px;
  height: 1px;
  background-color: $grey;
}
