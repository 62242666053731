@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column, $justify-content: space-between);
  margin-top: 10px;
}

.middle {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  margin-top: 10px;
}

.center {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  margin-top: 15px;
}

.line {
  margin-top: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid $grey;
  width: 100%;
}

.centerLine {
  margin-top: 15px;
  color: $grey;
  border-bottom: 1px solid $grey;
  width: 100%;
}

.tap {
  &:active {
    color: white;
  }
}
