@import '../variables';
@import '../mixins';

.wrapper {
  @include size($width: 100%, $height: 100%);
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: flex-end);
  .row {
    @include displayFlex($display: flex, $direction: row, $align-items: center);
    cursor: pointer;
  }
  .trash {
    color: $secondary;
  }
  .icon {
    @include size($width: 25px, $height: 25px);
    margin-left: 10px;
    color: $blue;
  }
}
