@import '../variables';
@import '../mixins';

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  width: fit-content;
  border-radius: 20px;
  background-color: $white;
  margin: 10px auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  row-gap: 5px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
  }
}

.group,
.active {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: flex-start);
  cursor: pointer;
  padding: 10px;
  max-width: 50px;

  p {
    color: black;
  }
}

.active {
  background-color: #efe5d3;

  p {
    color: $black;
  }
}

.flag {
  height: 20px;
  width: auto;
  border-radius: 50%;
  margin-right: 5px;
  aspect-ratio: 1/1;
}
