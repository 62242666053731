@import '../variables';
@import '../mixins';

.line {
  margin: 20px 0;
}

.line {
  margin: 20px 0;
  border-bottom: 1px solid $grey;
  width: 100%;
  @media only screen and (min-width: 768px) {
    width: 50%;
  }
}

.submit {
  &-container {
    @include displayFlex($display: flex, $justify-content: center);
    width: 100%;
    margin-top: 50px;
  }

  background-color: $blue !important;
}

.terms-container {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
}
.terms-text {
  margin-left: 10px;
  text-decoration: underline;
  color: black;
}

.subtitle {
  margin-bottom: 20px;
}

.error-message {
  color: $secondary;
  margin: 5px 0;
}

.country {
  border-radius: 20px;

  &-dropdown-container {
    margin-top: 15px;
    margin-bottom: 15px;
    @media only screen and (min-width: 768px) {
      width: 50%;
    }
  }
}

.dropdown-wrapper {
  @media only screen and (min-width: 768px) {
    @include displayFlex($display: flex, $align-items: center, $direction: column);
    min-width: 50%;
  }
}

.text {
  margin: 5px 0;
}

.form-wrapper {
  @media only screen and (min-width: 768px) {
    @include displayFlex($display: flex, $align-items: center, $direction: column, $justify-content: space-between);
    input,
    label,
    div,
    p {
      min-width: 50%;
    }
  }
}

.radio {
  @media only screen and (min-width: 768px) {
    @include displayFlex($display: flex, $align-items: center, $direction: row, $justify-content: space-between);
  }
}

.empty-page {
  margin-bottom: 120px;
}

.info {
  &-container {
    @include displayFlex($display: flex, $align-items: center, $direction: row);
    margin-top: 20px;
  }
  &-image {
    @include size($width: 21px, $height: 21px);
    margin: 0 5px 2px 2px;
    color: #1941a3;
  }
  &-description {
    @include font($size: 14px, $color: #1941a3, $weight: 400);
  }
}
