@import '../extends';
@import '../variables';
@import '../mixins';

.wrapper {
  @media only screen and (max-width: 768px) {
    @include position($position: fixed, $bottom: 0px);
  }

  .bottom-area {
    border-top: 1px solid $primary;
    min-height: 12px;
    width: 100%;

    @media only screen and (min-width: 768px) {
      display: none;
    }
  }

  @include size($width: stretch, $height: 74px);
  @media only screen and (min-width: 768px) {
    @include size($width: stretch, $height: 50px);
    margin-bottom: 10px;
  }
  width: -moz-available;
  @include displayFlex($display: flex, $direction: column, $align-items: center, $justify-content: space-between);
  background-color: $background;
  z-index: 899;
  .left-icons {
    width: 100%;
    @include displayFlex($display: flex, $direction: row, $justify-content: space-between);
    padding: 12px 0;
  }

  .container {
    @include size($width: 60%, $height: 100%);
    @include displayFlex($display: flex, $direction: column, $justify-content: space-between);
    @include font($color: $white, $size: 14px);

    .image {
      @include size($width: 100%, $height: auto, $max-width: 200px);
    }
  }

  .left-container {
    @include displayFlex($justify-content: flex-start, $align-items: flex-end);
    width: 40%;
    color: $primary;
  }

  .icon {
    &-container {
      @media only screen and (min-width: 768px) {
        margin: 0 10px;
      }
      @include displayFlex($display: flex, $justify-content: center, $align-items: center);
      @include size($width: 40px, $height: 40px);
      cursor: pointer;
      border-radius: 50%;
      background-color: $background;

      &-active {
        background-color: $white;
        @media only screen and (min-width: 768px) {
          margin: 0 30px;
        }
      }
    }

    color: $primary;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 30px;
  }

  @include media-breakpoint-down(xm) {
    padding: 0 30px;
  }

  @include media-breakpoint-down(xs) {
    padding: 0 20px;
  }
}
