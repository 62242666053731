@import '../variables';
@import '../mixins';

.info {
  &-container {
    @include displayFlex($display: flex, $align-items: flex-start, $direction: row);
    margin-bottom: 10px;
    padding: 5px 0 5px 15px;
  }
  &-image {
    @include size($width: 21px, $height: 21px);
    margin: 0 5px 2px 0;
    color: #1941a3;
  }
  &-description {
    @include font($size: 14px, $color: #1941a3, $weight: 400);
    margin-left: 12px;
  }
}
