@import '../variables';
@import '../mixins';

.wrapper-edit {
  @include displayFlex($display: flex, $direction: row, $justify-content: flex-end, $align-items: center);
  @include size($width: 100%, $height: 50px);
  color: $blue;
  cursor: pointer;
}
.wrapper-delete {
  @include displayFlex($display: flex, $direction: row, $justify-content: flex-end, $align-items: center);
  @include size($width: 100%, $height: 50px);
  color: $secondary;
  cursor: pointer;
}

.icon {
  @include size($width: 20px, $height: 20px);
  margin-left: 10px;
}

.spacer {
  height: 50px;
  width: 100%;
}
