@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex(
    $display: flex,
    $direction: row,
    $justify-content: flex-start !important,
    $align-items: center,
    $wrap: nowrap
  );
  padding: 0 !important;
  margin: 25px 0;
  font-weight: bold;

  p {
    white-space: nowrap;
  }
}

.logo {
  color: $secondary;

  &-wrapper {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
}

.toggle-icon {
  @include size($width: 20px !important, $height: 20px !important);
  background-color: $white;
  &-inactive {
    @include size($width: 20px !important, $height: 20px !important);
    fill: transparent;
    border-color: $white !important;
  }
}
