@import '../variables';
@import '../mixins';

.wrapper {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  margin-top: 40px;
  background-color: #f7f7f7;
}
