@import '../variables';
@import '../mixins';

.wrapper {
  box-shadow: $shadow;
  padding: 15px;
  border-radius: 10px;
  margin: 20px 0;
  overflow-wrap: anywhere;
}
.wrapper-simple {
  padding: 15px;
  border-radius: 10px;
  margin: 20px 0;
  overflow-wrap: anywhere;
}
.info-wrapper {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  cursor: pointer;
}
.contact-wrapper {
  @include displayFlex($display: flex, $direction: row, $justify-content: flex-end, $align-items: center);
}
.contact-icon {
  @include size($width: 25px, $height: 25px);
  color: $blue;
  &-wrapper {
    @include size($width: 25px, $height: 25px);
  }
}

.image {
  @include displayFlex($display: flex, $justify-content: center, $align-items: center);
  @include size($width: 100px, $height: 100px);
  object-fit: contain;
  border: 1px solid $border-grey;
  border-radius: 10px;
  padding: 2px;
  position: relative;
  &-container {
    position: relative;
  }
}
.description-container {
  min-width: 100px;
  min-height: 100px;
  margin-left: 15px;
  @include displayFlex($display: flex, $justify-content: space-between, $direction: column);
}
.title {
  font-weight: bold;
}
.description {
  @include font($weight: bold, $color: $primary);
}
.clientData {
  opacity: 0.6;
}
.logo-container {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
}
.logo {
  @include size($width: 20px, $height: 20px);
  color: $dark-grey;
  margin-right: 10px;
  &-me {
    color: $secondary;
    @include size($width: 20px, $height: 20px);
    margin-right: 10px;
  }
}
.swap-elements {
  @include displayFlex($display: flex, $direction: column-reverse);
}
