@import '../variables';
@import '../mixins';

.wrapper {
  @include size($width: 100%, $height: 100%);
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: flex-end);
  .icon {
    color: $blue;
    @include size($width: 25px, $height: 25px);
    margin-left: 10px;
  }
  .line {
    width: 2px;
    height: 60%;
    background-color: $dark-grey;
    margin: 0 10px;
  }
  .option {
    @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: flex-end);
    cursor: pointer;
  }
}
