@import '../variables';
@import '../mixins';

.search-bar {
  &-container {
    border: 1px solid $border-grey;
    border-radius: 20px;
    background-color: $white;
    @include size($width: stretch, $height: 28px);
    width: -moz-available;
    @include displayFlex($display: flex, $direction: row, $align-items: center);
  }
  &-margin {
    margin: 10px;
    margin-right: 0;
  }
  &-icon {
    @include size($width: 24px, $height: 24px);
    margin: 0 10px;
  }

  @include size($width: 100%, $height: 100%);
  outline: 0;
  border: 0;
  font-size: 16px;
  border-radius: 20px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0;
}
