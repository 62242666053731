@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $justify-content: space-between, $align-items: center);
  padding: 20px 0;
  padding-bottom: 8px !important;
  background-color: $background;
  height: 150px;
  margin: 50px 0 50px 0;

  @media only screen and (min-width: 768px) {
    margin: 50px 0 0 0;
  }

  &-no-margin {
    margin: 0;
  }

  .container {
    @include size($width: 60%, $height: 70%);
    @include displayFlex($display: flex, $direction: column, $justify-content: space-between);
    @include font($color: $white, $size: 14px);
    padding: 10px 5px 10px 0px;

    .text {
      cursor: pointer;
      margin: 5px 0;
      color: $white;
      text-decoration: none;
    }

    .imageStripe {
      @include size($width: 90%, $height: auto, $max-width: 200px);
      border-radius: 5px;
    }
    .imagePayment {
      @include size($width: 90%, $height: auto, $max-width: 200px);
      margin-top: 5px;
    }
  }

  .imageContainer {
    @include displayFlex($display: flex, $direction: column, $justify-content: space-between, $align-items: flex-end);
    margin-top: 15px;
  }

  .left-container {
    padding: 10px 0px 10px 5px;
    width: 40%;
    align-items: flex-end;
  }
}

.contact-link {
  color: $primary;
  text-decoration: none;
  cursor: pointer;
}

.margin-div {
  margin-top: 55px;
}

.link-wrapper {
  @include displayFlex($display: flex, $direction: column);
}
