@import '../variables';
@import '../mixins';

.page-wrapper {
  @include displayFlex($display: flex, $direction: column, $justify-content: space-between);
}

.wrapper {
  @include displayFlex($display: flex, $direction: row);
}

.pagination {
  &-container {
    display: block;
    @include media-breakpoint-down(sm) {
      //   display: none;
    }
    @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: center);
    width: 100%;
    height: 50px;
  }
  &-selected {
    background-color: $primary;
  }
  @include size($width: 30px, $height: 30px);
  @include displayFlex($display: flex, $justify-content: center, $align-items: center);
  color: $white;
  line-height: 0px;
  margin: 0 5px;
  font-size: 20px;
  background-color: $grey;
  border-radius: 50%;
  cursor: pointer;
}

.ad-wrapper {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  @include displayFlex($display: inline, $direction: column, $align-items: center);
  width: 50%;
  margin: 20px 0 0 20px;
  background-color: #f7f7f7;
}

.child-wrapper {
  width: 100%;
  @media only screen and (min-width: 768px) {
    height: 100%;
    padding: 0 10px;
  }
}

.space {
  height: 150px;
}

.spacer {
  height: 34px;
  width: 100%;
}

.no-side-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
