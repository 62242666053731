@import '../variables';
@import '../mixins';

.input-container {
  @include displayFlex($display: flex, $direction: column);
  width: 100%;
}

.line {
  margin: 20px 0;
}

.line {
  margin: 20px 0;
  border-bottom: 1px solid $grey;
  width: 100%;
}

.error-message {
  color: $secondary;
  margin: 5px 0;
}

.text {
  margin: 5px 0;
}
