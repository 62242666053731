@import '../variables';
@import '../mixins';

.wrapper {
  flex-wrap: wrap;
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: center);

  .modal-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: $white;
  }
  .image-container {
    position: relative;

    .image {
      @include size($width: 120px, $height: 120px);
      object-fit: cover;
      cursor: pointer;
      border: 1px solid $border-grey;
      border-radius: 10px;
      margin: 10px;
    }
    .logo {
      @include position($position: absolute, $bottom: 20px, $right: 20px);
      @include size($width: 25px, $height: 25px);
      border-radius: 50%;
      color: $white;
      border: 1px solid $primary;
      &-selected {
        background-color: $primary;
      }
      &-unselected {
        background-color: white;
      }
    }
  }
}

.grid {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: center, $wrap: wrap);
}
