@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: row);
  width: 100%;
  overflow-wrap: anywhere;

  .green-bubble {
    @include size($width: 40px, $height: 40px);
    border-radius: 50%;
    background-color: $blue;
    margin: 5px 2px 1px 5px;
    &-container {
      @include displayFlex($display: flex, $direction: column, $align-items: center);
      margin-right: 10px;
    }
    &-out {
      @include size($width: 50px, $height: 50px);
      border-radius: 50%;
      background-color: $border-grey;
    }
  }

  .bubble {
    @include size($width: 40px, $height: 40px);
    border-radius: 50%;
    background-color: $dark-grey;
    margin: 5px 2px 1px 5px;
    &-container {
      @include displayFlex($display: flex, $direction: column, $align-items: center);
      margin-right: 10px;
    }
    &-out {
      @include size($width: 50px, $height: 50px);
      border-radius: 50%;
      background-color: $border-grey;
    }
  }

  .right {
    width: 100%;
  }

  .long-line {
    background-color: $border-grey;
    width: 1px;
    height: 100%;
    margin: 0;
    flex: 2;
  }
  .short-line {
    background-color: $border-grey;
    width: 1px;
    height: 5px;
    margin: 0;
  }
}
