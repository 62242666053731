@import '../variables';
@import '../mixins';

.wrapper {
  box-shadow: $shadow;
  padding: 15px;
  border-radius: 10px;
  margin: 20px 0;
  overflow-wrap: anywhere;
}
.wrapper-simple {
  padding: 15px;
  border-radius: 10px;
  margin: 20px 0;
  overflow-wrap: anywhere;
}
.info-wrapper {
  position: relative;
  @include displayFlex($display: flex, $direction: row);
  cursor: pointer;
}
.contact-wrapper {
  @include displayFlex($display: flex, $direction: row, $justify-content: space-between, $align-items: center);
}
.contact-icon-circled {
  @include size($width: 25px, $height: 25px);
  color: $blue;
  cursor: pointer;
}

.contact-icon {
  @include size($width: 25px, $height: 25px);
  color: $blue;
  cursor: pointer;

  &-disabled {
    @include size($width: 25px, $height: 25px);
    color: grey;
    cursor: default;
  }

  &-wrapper {
    @include size($width: 25px, $height: 25px);
    @include displayFlex($display: flex, $direction: column, $align-items: center, $justify-content: center);
    padding: 3px;
    border: 1px solid $blue;
    border-radius: 50%;

    &-disabled {
      @include size($width: 25px, $height: 25px);
      @include displayFlex($display: flex, $direction: column, $align-items: center, $justify-content: center);
      padding: 3px;
      border: 1px solid grey;
      border-radius: 50%;
      cursor: default;
    }
  }
}

.image {
  @include displayFlex($display: flex, $direction: column, $align-items: center, $justify-content: center);

  @include size($width: 100px, $height: 100px);
  object-fit: contain;
  border: 1px solid $border-grey;
  border-radius: 10px;
  padding: 2px;
  position: relative;
  cursor: pointer;
  &-container {
    position: relative;
  }
}
.description-container {
  margin-left: 15px;
  @include displayFlex($display: flex, $justify-content: space-between, $direction: column);
}
.title {
  @include font($weight: bold, $size: 18px);
}
.description {
  opacity: 0.5;
}
.location {
  @include font($weight: bold, $color: $primary);
}
.logo-container {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  flex-wrap: wrap;
}
.logo {
  @include size($width: 20px, $height: 20px);
  color: $dark-grey;
  margin-right: 10px;
}

.line {
  margin: 12px 0 10px 0;
  border-bottom: 1px solid $grey;
  width: 100%;
}
.left-margin {
  margin-left: 5px;
}
.country-text {
  opacity: 0.6;
  margin-top: 2px;
}
