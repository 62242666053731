@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column, $align-items: center);
  margin: 40px 0;
}

.image {
  width: 80%;
  margin-bottom: 40px;
  max-width: 400px;
}

.title {
  font-weight: 700;
  text-align: center;
}
