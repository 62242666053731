@import '../variables';
@import '../mixins';

.wrapper {
  @include size($width: 100%, $height: 100%);
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
  .row {
    @include displayFlex($display: flex, $direction: row, $align-items: center);
    cursor: pointer;
  }
}

.right-align {
  @include size($width: 100%, $height: 100%);
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: right);
  .row {
    @include displayFlex($display: flex, $direction: row, $align-items: center);
    cursor: pointer;
  }
}

.delete-text {
  color: $secondary;
  font-weight: bold;
}
.edit-text {
  color: $blue;
  font-weight: bold;
}
.delete-icon {
  @include size($width: 20px, $height: 20px);
  margin-left: 10px;
  color: $secondary;
}
.button {
  color: $blue;
  @include clearOutline();
  @include font($weight: 700, $size: 16px, $color: $blue, $family: $font-family);
  background: linear-gradient($border-grey 42%, $white 78%);
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient($border-grey 42%, $white 78%);
  border-radius: 10px;
  min-width: 100px;
  height: 30px;
}

.warning {
  &-wrapper {
    @include displayFlex($display: flex, $direction: row, $align-items: center);
    margin: 12px 0 24px 0;
  }
}

.info-text {
  @include font($color: #1941a3);
}
