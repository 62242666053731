@import '../variables';
@import '../mixins';

.wrapper {
  z-index: 900;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}

.burger-opened {
  background-color: $dark-grey;
  position: fixed;
  top: 0px;
  right: 0;
  height: 150vh;
  width: 80%;
  margin-left: auto;
  margin-right: 0;
  z-index: 900;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 800px;
  transition-property: width, opacity;
  transition-duration: 1s;
  -webkit-transition-property: width, opacity;
  -webkit-transition-duration: 1s;
}

.burger-closed {
  background-color: $dark-grey;
  position: fixed;
  top: 0px;
  right: -80%;
  height: 150vh;
  width: 0%;
  margin-left: auto;
  margin-right: 0;
  z-index: 900;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 800px;
  content: '';
  transition-property: width, opacity, right;
  transition-duration: 1s;
  -webkit-transition-property: width, opacity, right;
  -webkit-transition-duration: 1s;
}

.blur-opened {
  position: fixed;
  top: 0;
  left: 0;
  height: 150vh;
  width: 20%;
  z-index: 900;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-bottom: 800px;
  content: '';
  backdrop-filter: blur(5px) contrast(80%);
  -webkit-backdrop-filter: blur(5px) contrast(80%);
  transition-property: opacity, left;
  transition-duration: 1s;
  -webkit-transition-property: opacity, left;
  -webkit-transition-duration: 1s;
}

.blur-closed {
  position: fixed;
  top: 0;
  left: 100%;
  height: 150vh;
  width: 0%;
  z-index: 900;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-bottom: 800px;
  backdrop-filter: blur(5px) contrast(80%) !important;
  -webkit-backdrop-filter: blur(5px) contrast(80%) !important;
  transition-property: opacity, left;
  transition-duration: 1s;
  -webkit-transition-property: opacity, left;
  -webkit-transition-duration: 1s;
}

.header {
  @include displayFlex($display: flex, $direction: row, $justify-content: space-between, $align-items: center);
  background-color: $background;
  color: white;
  height: 60px;

  svg {
    cursor: pointer;
  }
}

.title {
  color: white;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
}

.toggle-opener {
  @include displayFlex($display: flex, $direction: row, $justify-content: right, $align-items: center);
}

.content {
  padding-bottom: 100px !important;

  &-menu {
    padding-bottom: 100px !important;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
  }
}

.no-scroll {
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}
