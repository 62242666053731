@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: row);
  width: 100%;

  .notification-icon {
    @include size($width: 20px, $height: 20px);
    color: $dark-grey;
  }

  .row {
    @include displayFlex($display: flex, $direction: row, $align-items: center);
  }

  .flex {
    @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
  }

  .text {
    min-width: 50px;
    padding-right: 4px;
    &-blue {
      color: $blue;
      min-width: 50px;
      padding-right: 4px;
    }
  }

  .box {
    position: relative;
    border-radius: 10px;
    padding: 10px;
    background-color: $border-grey;
    cursor: pointer;
  }

  .tuner-box {
    margin-top: 8px;
  }

  .tuner-image {
    height: 26px;
    width: 26px;
    margin: 0 6px 0px 0;
    padding: 4px 0;
  }

  .description {
    @include displayFlex($display: flex, $direction: column, $justify-content: center, $align-items: space-between);
    width: 100%;
    margin: 5px 0 25px 0;
  }

  .title {
    margin-bottom: 10px;
  }

  .link {
    position: absolute;
    bottom: 4px;
    right: 12px;
    @include size($width: 24px, $height: 24px);
    color: $blue;
    margin: 0 8px;
    stroke-width: 0.6;
  }

  .toggle {
    position: absolute;
    top: 8px;
    right: 2px;
    margin: 0 10px;
    stroke-width: 0.8;
  }

  .instrument-title {
    color: #deab52;
    font-weight: bold;
  }

  .red-text {
    color: #d24444;
  }

  .info {
    &-image {
      @include size($width: 21px, $height: 21px);
      margin: 0 8px 0px 0;
      padding: 4px 0;
    }
  }
}
