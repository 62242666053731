@import '../variables';
@import '../mixins';

.wrapper {
  @include size($width: 100%, $height: 100%);
  @include displayFlex($display: flex, $direction: column, $align-items: center, $justify-content: space-around);
  text-align: center;
  min-height: 200px;
}

.btn-container {
  @include displayFlex($display: flex, $direction: row, $justify-content: space-around);
  width: 100%;
}

.title {
  font-weight: bold;
}

.content {
  white-space: initial;
  padding: 24px 0;
}
