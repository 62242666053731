@import '../variables';
@import '../mixins';

.input {
  border: 0;
  &-container {
    margin: 10px 0;
    @include displayFlex($display: flex, $direction: row, $align-items: center);
  }

  @include font($size: 16px, $color: $black, $family: $font-family);
  @include size($width: stretch);
  border-radius: 15px;
  border: 1px solid $border-grey;
  outline: 0;
  font-size: 16px;
  padding: 5px 10px;
}

.date-text {
  width: 200px;
  flex-basis: 35%;
}

.btn-container {
  @include displayFlex($display: flex, $justify-content: center);
  margin: 40px 0;
  &-secondary {
    @include displayFlex($display: flex, $justify-content: center);
    margin: 40px 0 10px 0;
  }
}

.bubble {
  margin: 10px 0;
}

.error-message {
  color: $secondary;
}
.info {
  &-container {
    @include displayFlex($display: flex, $align-items: center, $direction: row);
    margin-bottom: 10px;
  }
  &-image {
    @include size($width: 21px, $height: 21px);
    margin: 0 5px 2px 0;
    color: #1941a3;
  }
  &-description {
    @include font($size: 13px, $color: #1941a3, $weight: 400);
  }
}
.flex {
  @include displayFlex($display: flex, $align-items: center, $direction: row);
}
.padding {
  padding-right: 15px;
}
.indented {
  margin-left: 10px;
}
.repeat-value {
  margin: 0 15px;
  width: 35%;
  text-align: center;
  padding: 5px !important;
}
.last-element {
  margin-top: 50px;
}
.value {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  height: 30px;
  border-radius: 15px;
  margin: 0 16px;
  width: 60%;
  &:empty:before {
    content: '-';
  }
}
.no-margin {
  margin: 0 !important;
}
.delete-icon {
  @include size($width: 25px, $height: 25px);
  color: $blue;
  cursor: pointer;
}
.row {
  @include displayFlex($display: flex, $align-items: center, $direction: row);
  width: 100%;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.react-date-picker__wrapper {
  border: 0;
}
