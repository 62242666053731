@import '../variables';
@import '../mixins';

.btn {
  &-wrapper {
    @include displayFlex($display: flex, $justify-content: center);
    margin: 20px 0;
  }
}

.title {
  width: 94%;
}

.error-text {
  margin-bottom: 5px;
  color: $secondary;
}
.indented {
  margin-left: 10px;
}
.last-element {
  height: 50px;
}
.inp {
  min-height: 30px;
  padding: 0 10px !important;
  margin: 0 !important;
  @include displayFlex($display: flex, $align-items: center);
}
