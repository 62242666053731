@import '../variables';
@import '../mixins';

.btn-container {
  @include displayFlex($display: flex, $direction: row, $justify-content: center);
  padding: 75px 0 85px 0;
}

.spacer {
  height: 1200px;
}

.no-space {
  height: 400px;
}

.inactive {
  background-color: #808080 !important;
  cursor: not-allowed;
}
