@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column, $align-items: center);
  width: 100%;
}

.top-row {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  width: 100%;
}

.history-row {
  opacity: 0;
  width: 0px;
  height: 0px;

  &-visible {
    @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: flex-start);
    gap: 4px;
    width: 100%;
    opacity: 1;
    height: auto;
    transition: opacity 0.3s ease-in, transform 0.3s ease-in;
  }
}

.history-item {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  gap: 4px;
  padding: 5px 10px !important;
  border: 1px gray solid;
  border-radius: 50px;

  @media only screen and (max-width: 768px) {
    p {
      max-width: 80px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:hover {
    border: 1px $primary solid;
    cursor: pointer;
  }

  @media (hover: hover) {
    &:hover {
      border: 1px $primary solid;
    }
  }
}
