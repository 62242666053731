@import '../variables';
@import '../mixins';

.wrapper {
  @include size($height: 30px);
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: left);
  margin-left: 0;
  cursor: pointer;
}

.content {
  cursor: default;
}

.link {
  cursor: pointer;

  div {
    white-space: nowrap;
  }
}

.inactive {
  cursor: not-allowed;
}
