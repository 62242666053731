@import '../variables';
@import '../mixins';

.row {
  @include displayFlex($display: flex, $direction: row, $justify-content: space-between);
  &-simple {
    @include displayFlex($display: flex, $direction: row, $align-items: center);
    margin: 15px 0 15px 10px;
  }
  &-button {
    @include displayFlex($display: flex, $justify-content: center);
    margin: 20px 0;
  }
}

.icon {
  @include size($width: 25px, $height: 25px);
  color: $primary;
}
.address {
  margin: 0 20px;
  overflow-wrap: anywhere;
}
.logos {
  @include size($width: 25px, $height: 25px);
  color: $blue;
}
.last-element {
  padding-bottom: 30px;
}
.link {
  cursor: pointer;
  overflow-wrap: anywhere;
}
