@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $align-items: center, $direction: row);
  border: 1px solid $blue;
  border-radius: 10px;
  margin-bottom: 24px;
}

.menu-item {
  @include displayFlex($display: flex, $align-items: center, $justify-content: center, $direction: row);
  margin: 4px 16px;
  cursor: pointer;

  &:active {
    color: $background;
  }

  @media only screen and (min-width: 768px) {
    @include displayFlex($display: flex, $align-items: center, $justify-content: center, $direction: row);
    margin: 6px 24px;
  }

  color: $dark-grey;

  svg {
    height: 22px;
    width: 22px;
  }
}
