@import '../variables';
@import '../mixins';

.row {
  margin: 15px 0;
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  &-idented {
    margin-left: 25px;
  }
}

.icon {
  @include size($width: 25px, $height: 25px);
  color: $blue;
  margin-right: 15px;
  cursor: pointer;
  &-attach {
    margin-left: 15px;
  }
}
