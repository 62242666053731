@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
  @include size($width: 100%, $height: 100%);
}

.new-wrapper {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  cursor: pointer;
}
