@import '../variables';
@import '../mixins';

.row {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
  margin: 10px 0 10px 10px;
}

.next-text {
  color: $blue;
}

.error-text {
  color: $secondary;
}

.date-input {
  min-width: 60%;
  width: 60%;
  margin: 0 15px;
}

.delete-icon {
  @include size($width: 25px, $height: 25px);
  color: $blue;
  margin-top: 5px;
  cursor: pointer;
}

.input-wrapper {
  position: relative;
  width: 70%;
}

.info {
  &-container {
    @include displayFlex($display: flex, $align-items: center, $direction: row);
    margin-bottom: 10px;
  }
  &-image {
    @include size($width: 21px, $height: 21px);
    margin: 0 5px 2px 0;
    color: #1941a3;
  }
  &-description {
    @include font($size: 14px, $color: #1941a3, $weight: 400);
  }
}

.clock-info {
  display: flex;
  align-items: center;

  &-icon {
    @include size($width: 24px, $height: 24px);
    color: $green;
    margin-left: 8px;
  }

  input,
  p {
    margin-left: 16px;
    flex: 1;
  }
}
