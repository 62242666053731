@import '../variables';
@import '../mixins';

.wrapper {
  height: 50px;
  width: 100%;
  background-color: white;
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
}

.title {
  @include font($text-transform: uppercase, $weight: bold);
}

.id {
  font-size: 14px;
}
