@import '../variables';
@import '../mixins';

.edit-attribute {
  width: 50%;
  text-align: center;
  &-primary {
    color: $primary;
  }
}

.attribute {
  width: 170px;
  text-align: center;

  &-primary {
    color: $primary;
  }
}

.row {
  margin: 15px 0 15px 30px;
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  input {
    margin: 0 10px;
  }

  &-center {
    margin: 15px 0;
    @include displayFlex($display: flex, $direction: row, $align-items: center);
    input {
      margin: 0 10px;
    }
  }
}

.icon {
  @include size($width: 25px, $height: 25px);
  color: $blue;
  margin-right: 15px;

  &-container {
    @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: flex-start);
  }
}

.currency-container {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: flex-start);
}
