@import '../variables';
@import '../mixins';

.radio {
  justify-content: flex-start !important;
  margin: 10px 0;
}

.inp-container {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  width: 100%;
  width: -moz-available;
}

.icon {
  @include size($width: 20px !important, $height: 20px !important);
}

.icon-inactive {
  @include size($width: 20px !important, $height: 20px !important);
  fill: transparent;
  border-color: $white !important;
}

.title {
  font-weight: bold;
  margin: 10px 0;
  white-space: nowrap;
}

.search-container {
  width: stretch;
  width: -moz-available;

  @include media-breakpoint-down(sm) {
    width: 90%;
  }
}

.line {
  border-color: $white;
}

.flex {
  @include displayFlex($display: flex, $direction: row);
}

.category-container {
  @include font($family: $font-family, $size: 16px);
  width: 100%;
  margin: 10px 0;
  padding: 5px 10px;
  cursor: pointer;

  option {
    padding: 5px 0;
    width: 50%;
  }
}

.spacer {
  width: 0;
  height: 0;
  padding: 12px;
}
