@import '../variables';
@import '../mixins';

.modal {
  @include position($position: fixed, $left: 50%, $top: 50%);
  @include size($width: 280px, $height: fit-content, $max-width: 100%, $max-height: 100%);
  min-height: 200px;

  @media only screen and (min-width: 768px) {
    @include size($width: 25%, $height: fit-content, $max-width: 100%, $max-height: 100%);
    min-height: 200px;
  }

  background-color: $white;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 20px;
  z-index: 4;

  &-nocontent {
    @include position($position: fixed, $left: 50%, $top: 50%);
    @include size($width: auto, $height: 80%, $max-width: 100%, $max-height: 100%);

    @media only screen and (max-width: 768px) {
      @include size($width: 90%, $height: auto, $max-width: 100%, $max-height: 100%);
    }

    transform: translate(-50%, -50%);
    object-fit: contain;
    z-index: 4;
  }
}

.back {
  @include position($position: fixed, $top: 0, $left: 0);
  @include size($width: 100%, $height: 100%);
  z-index: 3;
  background-color: transparent;
}

.back::before {
  background-color: rgba(0, 0, 0, 0.9);
  content: '';
  filter: blur(18px);
  position: absolute;
  inset: -10%;
  z-index: 3;
}

.icon {
  @include position($position: absolute, $top: 10px, $right: 10px);
  @include size($width: 25px, $height: 25px);
  z-index: 5;
  cursor: pointer;
}
