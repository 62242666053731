@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column);
  padding-top: 10px;
}

.row {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  margin: 10px 0;
}
.center-row {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: center);
}
.download-row {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
  padding: 15px 0;
  margin: 0;
  cursor: pointer;
}

.language-container {
  @include font($family: $font-family, $size: 16px);
  width: 50%;
  margin: 30px 0;
  padding: 5px 10px;
  cursor: pointer;

  option {
    padding: 5px 0;
  }

  &:disabled {
    padding: 5px 10px;
    background-color: white;
    opacity: 1;
    color: black;
    -webkit-text-fill-color: black;
    appearance: none;
    -webkit-appearance: none;
  }
}
.blue-title {
  @include font($color: $blue, $weight: bold);
  margin: 20px 0;
}

.icon {
  @include size($width: 25px, $height: 25px);
}
.currency {
  margin-left: 15px;
  width: 30%;

  &:disabled {
    background-color: white;
  }
}
.unit {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  margin: 0 0 10px 30px;

  &-row {
    @include displayFlex($display: flex, $direction: row);
  }

  &-text {
    padding-top: 10px;
  }

  &-title {
    width: 35px;
    text-align: center;
  }
}

.indented {
  margin-left: 10px;
}

.last-element {
  padding-bottom: 50px;
}

.left {
  margin-right: 15px;
}

.line {
  margin: 0;
  height: 1px;
  background-color: $grey;
}

.user-db-description {
  color: $dark-grey;
}

.info {
  &-container {
    @include displayFlex($display: flex, $align-items: center, $direction: row);
    margin-bottom: 10px;
  }
  &-image {
    @include size($width: 21px, $height: 21px);
    margin: 0 5px 2px 0;
    color: #1941a3;
  }
  &-description {
    @include font($size: 14px, $color: #1941a3, $weight: 400);
  }
}
