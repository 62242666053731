@import '../variables';
@import '../mixins';

.row {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: center);
  margin: 15px 0;

  &-memo {
    @include displayFlex($display: flex, $direction: row, $align-items: center);
    margin: 10px 0;
  }
}
.input {
  @include size($width: 100%, $height: 25px);
  border-radius: 15px;
  border: 1px solid $border-grey;
  outline: 0;
  margin-left: 15px;
  font-size: 16px;
  padding-left: 10px;
}
.input-line {
  margin: 0;
}
.input-left {
  margin: 0;
  margin-right: 15px;
}
.icon {
  @include size($width: 25px, $height: 25px);
  color: $blue;
}
.phone-container {
  margin-top: 50px;
}
.btn-container {
  @include displayFlex($display: flex, $justify-content: center, $align-items: center);
  margin: 30px 0;
}

.error-message {
  margin-top: 10px;
  color: $secondary;
}

.double-row {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  p {
    width: 50%;
  }
  .left-p {
    margin-left: 25px;
  }
}

.disconnect-instrument {
  margin: 15px 0 15px 20px;
  color: #1941a3;
}
.info {
  &-container {
    @include displayFlex($display: flex, $align-items: center, $direction: row);
    margin: 15px 0 0 3px;

    &-no-padding {
      @include displayFlex($display: flex, $align-items: center, $direction: row);
      margin: 0px 0 0 3px;
    }
  }
  &-image {
    @include size($width: 21px, $height: 21px);
    margin: 0 5px 2px 0;
    color: #1941a3;
  }
  &-description {
    @include font($size: 13px, $color: #1941a3, $weight: 400);
  }
}
.connect-instrument {
  @include font($weight: bold, $color: $primary);
  margin: 10px 0;
}
.indented {
  margin-left: 10px;
}

.type-title {
  margin-bottom: 10px;
  margin-top: 10px;
}

.company-name {
  margin: 10px 0;
}
