@import '../variables';
@import '../mixins';

.wrapper {
  margin-top: 20px;
}

.collapse {
  transition: height 0.5s;
}

.header {
  @include displayFlex($display: flex, $direction: row, $justify-content: space-between, $align-items: center);
  font-weight: bold;

  &-not-bold {
    @include displayFlex($display: flex, $direction: row, $justify-content: space-between, $align-items: center);
  }
}
.logo {
  @include size($width: 25px, $height: 25px);
  cursor: pointer;
  &-container {
    @include size($width: 25px, $height: 25px);
  }
}

.line {
  margin: 10px 0;
  border-bottom: 1px solid $grey;
  width: 100%;
}
