@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex(
    $display: flex,
    $direction: row,
    $align-items: center,
    $justify-content: space-between,
    $wrap: wrap
  );
  height: 60px;
  div {
    @include displayFlex($display: flex, $direction: row, $align-items: center);
  }
}

.horizontal {
  @include size($width: 2px, $height: 20px);
  margin: 0 10px;
  background-color: $border-grey;
}

.title {
  @include font($color: $black, $text-transform: uppercase);
  cursor: pointer;
  &-selected {
    @include font($color: $primary, $text-transform: uppercase);
    cursor: pointer;
  }
}

.line {
  margin: 0;
  height: 1px;
  background-color: $grey;
}

.active {
  &-text {
    padding: 0 6px;
  }

  &-wrapper {
    order: 2;
    flex: 1;
    justify-content: flex-end;
  }
}

.category-wrapper {
  order: 1;
  flex: 1;
}
