@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: row);
  overflow-wrap: anywhere;

  .green-bubble {
    @include displayFlex($display: flex, $direction: column, $align-items: center, $justify-content: center);
    @include size($width: 40px, $height: 40px);
    @include font($size: 20px, $color: $white, $family: $font-family);

    border-radius: 50%;
    background-color: $blue;
    margin: 5px 2px 1px 5px;
    &:hover {
      cursor: pointer;
    }
    &-container {
      @include displayFlex($display: flex, $direction: column, $align-items: center);
      margin-right: 10px;
    }
    &-out {
      @include size($width: 50px, $height: 50px);
      border-radius: 50%;
      background-color: $border-grey;
    }
  }

  .bubble {
    @include displayFlex($display: flex, $direction: column, $align-items: center, $justify-content: center);
    @include size($width: 40px, $height: 40px);
    @include font($size: 20px, $family: $font-family);

    border-radius: 50%;
    background-color: $dark-grey;
    margin: 5px 2px 1px 5px;
    &:hover {
      cursor: pointer;
    }
    &-container {
      @include displayFlex($display: flex, $direction: column, $align-items: center);
      margin-right: 10px;
    }
    &-out {
      @include size($width: 50px, $height: 50px);
      border-radius: 50%;
      background-color: $border-grey;
    }
    &-out-red {
      @include size($width: 50px, $height: 50px);
      border-radius: 50%;
      background-color: #d24444;
    }
  }
  .right {
    width: 100%;
  }
  .short-line {
    background-color: $border-grey;
    width: 1px;
    height: 5px;
    margin: 0;
  }
  .mid-line {
    background-color: $border-grey;
    width: 1px;
    height: 24px;
    margin: 0;
  }
  .long-line {
    background-color: $border-grey;
    width: 1px;
    height: 100%;
    margin: 0;
    flex: 2;
  }
  .clock-icon {
    height: 21px;
    width: 21px;
  }
}

.detail-wrapper {
  @include displayFlex($display: flex, $direction: row);
  width: 100%;

  .notification-icon {
    @include size($width: 20px, $height: 20px);
    color: $dark-grey;
  }
  .row {
    @include displayFlex($display: flex, $direction: row);
  }
  .row-center {
    @include displayFlex($display: flex, $direction: row, $align-items: center);
  }
  .flex {
    @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
  }
  .text {
    min-width: 50px;
    padding-right: 4px;
    &-blue {
      color: $blue;
      min-width: 50px;
      padding-right: 4px;
    }
  }
  .box {
    border-radius: 10px;
    padding: 10px;
    background-color: $border-grey;
    cursor: pointer;
  }
  .tuner-box {
    margin-top: 8px;
  }
  .tuner-image {
    height: 26px;
    width: 26px;
    margin-right: 6px;
  }
  .description {
    @include displayFlex($display: flex, $direction: column, $justify-content: center, $align-items: space-between);
    width: 100%;
    margin: 5px 0 25px 0;
  }
  .title {
    margin-bottom: 10px;
  }
  .link {
    &-icon {
      @include size($width: 20px, $height: 20px);
      color: $blue;
      margin: 0 10px;
      stroke-width: 0.8;
    }

    &-container {
      @include displayFlex($display: flex, $direction: row, $justify-content: flex-end);
    }
  }
  .date-title {
    color: #deab52;
    font-weight: bold;
  }

  .instrument-title {
    color: $blue;
    font-weight: bold;
    margin: 4px 0;
  }
  .icon {
    @include size($width: 21px, $height: 21px);
    padding-left: 8px;
  }
  .red-icon {
    color: $secondary;
    margin-right: 12px;
    margin-left: 1px;
  }
  .logo {
    @include size($width: 20px, $height: 20px);
    color: $dark-grey;
    margin-right: 10px;
  }
  .lighter {
    opacity: 0.6;
    padding-right: 8px;
  }
  .image {
    @include displayFlex($display: flex, $justify-content: center, $align-items: center);
    @include size($width: 100px, $height: 100px);
    object-fit: contain;
    border: 1px solid $border-grey;
    border-radius: 10px;
    padding: 2px;
    position: relative;

    &-wrapper {
      position: relative;
    }
    &-row {
      @include displayFlex($display: flex, $direction: row, $align-items: center);
      cursor: pointer;
    }
    &-icon {
      @include size($width: stretch, $height: auto);
      padding: 25px;
      background-color: $dark-grey;
      color: $white;
      border-radius: 10px;
      cursor: pointer;
    }
  }

  .date-time-info-wrapper {
    margin: 12px 0 0 0;
    p {
      font-size: 16px;
    }
  }

  .info {
    &-red {
      margin: 0 8px;
      @include size($width: 22px, $height: 22px);
      path {
        fill: #d24444;
      }
    }
  }
}
