@import '../variables';
@import '../mixins';

.submit-container {
  margin-top: 60px;
  @include displayFlex($display: flex, $justify-content: center);
}

.form-wrapper {
  @media only screen and (min-width: 768px) {
    @include displayFlex($display: flex, $align-items: center, $direction: column);
    * {
      min-width: 50%;
    }
  }
}
