@import 'variables';
@import 'mixins';

button {
  cursor: pointer;
  &.primary {
    @include clearOutline();
    @include font($weight: 700, $size: 16px, $color: $white, $family: $font-family);
    border: 0;
    border-radius: 15px;
    min-width: 100px;
    height: 40px;
    background-color: $primary;
  }
  &.blue {
    @include clearOutline();
    @include font($weight: 700, $size: 16px, $color: $white, $family: $font-family);
    border: 0;
    border-radius: 15px;
    min-width: 100px;
    height: 40px;
    background-color: $blue;
    padding-left: clamp(8px, 12px, 18px);
    padding-right: clamp(8px, 12px, 18px);
  }
  &.secondary {
    @include clearOutline();
    @include font($weight: 700, $size: 16px, $color: $white, $family: $font-family);
    border: 0;
    border-radius: 15px;
    min-width: 100px;
    height: 40px;
    background-color: $secondary;
  }
  &.mixed {
    @include clearOutline();
    @include font($weight: 700, $size: 16px, $color: $white, $family: $font-family);
    border: 0;
    border-radius: 15px;
    min-width: 100px;
    height: 30px;
    background-color: $blue;
  }
  &.white {
    @include clearOutline();
    @include font($weight: 700, $size: 16px, $color: $blue, $family: $font-family);
    border: 2px solid $blue;
    border-radius: 15px;
    min-width: 100px;
    height: 30px;
    background-color: $white;
  }
  &.grey {
    @include clearOutline();
    @include font($weight: 700, $size: 16px, $color: #659892, $family: $font-family, $line-height: 14px);
    border: 2px solid $primary;
    border-radius: 15px;
    min-width: 100px;
    height: 36px;
    background-color: $grey;
  }
  &:disabled {
    background-color: $grey;
    -webkit-appearance: none;
    -webkit-text-fill-color: black;
    opacity: 1;
  }
}

.h2 {
  font-size: 20px !important;
}

.h3 {
  font-size: 16px !important;
}

.h4 {
  font-size: 14px !important;
}

.h5 {
  font-size: 12px !important;
}

.bubble {
  overflow-wrap: anywhere;
  padding: 5px 10px;
  border: 1px solid $border-grey;
  border-radius: 15px;
  margin-right: 10px;
  margin-left: 10px;
  min-width: 20%;
  &:empty:before {
    content: '-';
  }
}
.bubble-no-p {
  border: 1px solid $border-grey;
  border-radius: 15px;
  option {
    background-color: white;
  }
}

.bubble-instrument {
  padding: 5px 10px;
  border: 1px solid $border-grey;
  border-radius: 15px;
  margin-right: 10px;
  margin-left: 10px;
  width: 60%;
  overflow-wrap: anywhere;

  &:empty:before {
    content: '-';
  }
}

.bubble-client {
  padding: 5px 10px;
  border: 1px solid $border-grey;
  border-radius: 15px;
  margin-right: 10px;
  margin-left: 10px;
  min-width: 30%;
  &:empty:before {
    content: '-';
  }
}

.bubble-center {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: center);
  padding: 5px 0;
  border: 1px solid $border-grey;
  border-radius: 15px;
  margin: 0 15px;
  width: 36%;
  &:empty:before {
    content: '-';
  }
}

.bubble-date {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: center);
  padding: 5px 0;
  border: 1px solid $border-grey;
  border-radius: 15px;
  margin: 0 15px;
  width: 60%;
  &:empty:before {
    content: '-';
  }
}

input {
  &::placeholder {
    color: #a9a9a9;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #a9a9a9;
  }

  &::-ms-input-placeholder {
    color: #a9a9a9;
  }

  &.primary {
    @include clearOutline();
    @include font($size: 16px, $color: $black, $family: $font-family);
    height: 30px;
    border-radius: 15px;
    font-size: 16px;
    padding: 0 10px;
    border: 1px solid $border-grey;
    &:disabled {
      background-color: white;
      -webkit-appearance: none;
      -webkit-text-fill-color: black;
      opacity: 1;
    }
  }
  &:disabled {
    background-color: white;
    -webkit-appearance: none;
    -webkit-text-fill-color: black;
    opacity: 1;
  }
}

input[type='file'] {
  @include size($width: 0.1px, $height: 0.1px);
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

textarea {
  width: stretch;
  width: -moz-available;
  border: 1px solid $border-grey;
  border-radius: 15px;
  padding: 10px;
  @include font($size: 16px, $family: $font-family);

  &:disabled {
    background-color: white;
    color: black;
    -webkit-appearance: none;
    -webkit-text-fill-color: black;
    opacity: 1;
  }

  &:empty:before {
    content: '-';
  }
}

.radio-item {
  @include size($width: 25px, $height: 25px);
  content: ' ';
  border: 1px solid $primary;
  border-radius: 50%;
  position: relative;
  color: white;
  cursor: pointer;

  &-selected {
    background-color: $primary;
    color: white;
    &:after {
      @include size($width: 15px, $height: 15px);
      @include position($position: absolute, $top: 0px, $left: 0px);
      content: ' ';
      background-color: $primary;
      border-radius: 50%;
    }
  }

  &-disabled {
    background-color: #808080 !important;
    color: #808080 !important;
    border: 1px solid #808080;
    cursor: not-allowed;
  }
}
.radio {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
  padding: 5px 0;

  &-container {
    @include displayFlex($display: flex, $justify-content: center, $align-items: center);
    margin-right: 5px;
    position: relative;
    cursor: pointer;
  }

  &-instrument {
    @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: left);
    padding: 0 0 0 5px;
  }
}

.plus-circle {
  @include size($width: 25px, $height: 25px);
  margin-left: 10px;
  color: $white;
  background-color: $primary;
  border-radius: 50%;
  padding: 3px;
}

.edit-icon {
  @include size($width: 20px, $height: 20px);
  margin-left: 10px;
  color: $blue;
}

.filter-icon {
  @include size($width: 30px, $height: 30px);
  color: $border-grey;
  background-color: $white;
  border-radius: 5px;
  margin-right: 10px;
}
.image-edit-icon {
  @include size($width: 20px, $height: 20px);
  @include position($position: absolute, $bottom: -5px, $right: -5px);
  color: $white;
  padding: 10px;
  border-radius: 50%;
  background-color: $grey;
  cursor: pointer;
}

.image-delete-icon {
  @include size($width: 20px, $height: 20px);
  @include position($position: absolute, $bottom: -5px, $left: -5px);
  color: $secondary;
  padding: 10px;
  border-radius: 50%;
  background-color: $grey;
  cursor: pointer;
}
