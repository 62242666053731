@import '../variables';
@import '../mixins';

.wrapper {
  margin: 15px 0;
  font-weight: bold;
  cursor: pointer;
}

.line {
  margin: 10px 0;
  border-bottom: 1px solid $grey;
  width: 100%;
}
