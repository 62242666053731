@import '../variables';
@import '../mixins';

.reset-container {
  @include displayFlex($display: flex, $justify-content: center);
  width: stretch;
}

.wrapper {
  position: relative;
}

.filter {
  &-container {
    @include displayFlex($display: flex, $direction: row, $align-items: center);
    flex-wrap: wrap;
  }
  &-text {
    margin-right: 15px;
  }

  @include displayFlex($display: flex, $direction: row, $align-items: center);
  padding: 5px 10px !important;
  margin-top: 10px;
  min-width: 0% !important;
}
.btn {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 35px !important;
}

.link {
  cursor: pointer;
}
