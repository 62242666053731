@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: row);
  width: 100%;

  .notification-icon {
    @include size($width: 20px, $height: 20px);
    color: $dark-grey;
  }
  .row {
    @include displayFlex($display: flex, $direction: row);
  }
  .flex {
    @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
  }
  .text {
    min-width: 50px;
    padding-right: 4px;

    &-blue {
      color: $blue;
      min-width: 50px;
      padding-right: 4px;
    }
  }
  .box {
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;
    background-color: $border-grey;
  }
  .description {
    @include displayFlex($display: flex, $direction: column, $justify-content: center, $align-items: space-between);
    width: 100%;
    margin: 5px 0 25px 0;
  }
  .title {
    display: table;
    color: $primary;
    font-weight: bold;
  }
  .sub-title {
    margin-bottom: 10px;
  }
  .instrument-category {
    margin-left: 5px;
    color: #000000;
    opacity: 0.6;
    font-weight: 400;
  }
  .link {
    &-icon {
      @include size($width: 20px, $height: 20px);
      color: $blue;
      margin: 0 10px;
      stroke-width: 0.8;
    }

    &-container {
      @include displayFlex($display: flex, $direction: row, $justify-content: flex-end);
    }
  }
}
