@import '../variables';
@import '../mixins';

.attribute {
  width: 170px;
  text-align: center;
  &-primary {
    color: $primary;
  }
}

.row {
  margin: 15px 0 15px 30px;
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  input {
    margin: 0 5px;
    width: 50%;
  }
}

.note {
  @include size($width: stretch, $height: auto);
  border: 1px solid $border-grey;
  border-radius: 10px;
  padding: 10px;
}

.text {
  min-width: 25px;
}

.icon {
  @include size($width: 25px, $height: 25px);
  color: $blue;
  margin-right: 15px;
}

.white-input {
  background-color: white;
}
