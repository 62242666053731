$grid-breakpoints: (
  xs: 0,
  xm: 420px,
  sm: 576px,
  md: 768px,
  lg: 991px,
  xl: 1200px,
  xxl: 1500px,
) !default;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;

$white: #fff !default;
$black: #222222 !default;
$primary: #deab52 !default;
$secondary: #8b0000 !default;
$background: #2f2f2f !default;
$border-grey: #e5e5e5 !default;
$dark-grey: #bdbdbd !default;
$placeholder-grey: #a9a9a9 !default;
$grey: #e0e0e0 !default;
$loader-background-color: rgba(255, 255, 255, 0.1) !default;
$blue: #266e6a !default;
$green: #659892 !default;

$shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
  rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;

$font-family: Georgia, 'Times New Roman', serif;
