@import '../variables';
@import '../mixins';

.icon-part {
  @include displayFlex($display: flex, $direction: column, $align-items: center, $justify-content: center);
  @include size($height: 100%);
  cursor: pointer;
  margin-right: 10px;
}

.line {
  height: 15px;
  margin: 0;
  flex-grow: 1;
}

.text-part {
  @include font($color: $primary, $weight: bold);
  overflow-wrap: anywhere;
}

.cursor-pointer {
  cursor: pointer;
}

.instrument-container {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: left);
}

.icon {
  @include size($width: 25px, $height: 25px);
  color: $blue;
  flex-grow: 1;
}

.info {
  &-container {
    @include displayFlex($display: flex, $align-items: center, $direction: row);
    margin-bottom: 20px;
    margin-top: 10px;
  }
  &-image {
    @include size($width: 21px, $height: 21px);
    margin: 0 5px 2px 2px;
    color: #1941a3;
  }
  &-description {
    @include font($size: 14px, $color: #1941a3, $weight: 400);
  }
}
