@import '../variables';
@import '../mixins';

.wrapper {
  height: 60px;
  background-color: $background;
  @media only screen and (min-width: 768px) {
    height: 100px;
  }
}

.section {
  display: none;
  @media only screen and (max-width: 768px) {
    @include displayFlex($display: flex, $direction: row, $justify-content: space-between, $align-items: center);
    @include font($weight: bold, $color: $white);
    height: 100%;
  }
}

.tablet-view {
  display: none;
  @media only screen and (min-width: 768px) {
    margin: 0;
    @include displayFlex($display: flex, $direction: column, $justify-content: space-between, $align-items: center);

    .header-menu {
      height: 60px;
      width: 95%;
      @include displayFlex($display: flex, $direction: row, $justify-content: space-between, $align-items: center);
    }

    .burger-menu {
      &-points {
        height: 40px;
        width: 95%;
        @include displayFlex($display: flex, $direction: row, $justify-content: right, $align-items: center);
        @include font($weight: normal, $color: $white);
      }
      &-point {
        @include displayFlex($display: flex, $direction: row, $align-items: center);
        text-decoration: none;
        @include font($weight: normal, $color: $white);
        cursor: pointer;

        .horizontal-line {
          margin: 0 5px;
          cursor: default;
        }
      }
    }

    .line {
      margin: 0;
      width: 100%;
      border-bottom: 1px solid white;
    }
  }
}

.headerLeft {
  @media only screen and (min-width: 768px) {
    @include displayFlex($display: flex, $direction: row, $justify-content: space-between, $align-items: center);
    @include font($weight: bold, $color: $white);
    height: 100%;
  }
}

.title {
  @include font($weight: bold, $color: $white);
  padding-left: 15px;
}

.modal {
  @include displayFlex($display: flex !important, $direction: column, $justify-content: space-between);
  width: stretch;
  height: calc(100dvh - 60px);
}

.btn {
  &-container {
    @include displayFlex($display: flex, $direction: column);
    @include size($width: stretch);
  }
  cursor: pointer;
  color: black;
}

.menu {
  width: 100vw !important;
  height: 100vh !important;
  top: 0px;
  border: 0;
  outline: 0;
}

.logo {
  height: 70%;
  width: auto;
  border-radius: 50%;
  &-back {
    @include size($width: 20px, $height: 20px);
    padding: 5px;
    cursor: pointer;
  }
}

.footer-wrapper {
  margin-top: auto;
}

.burger-menu-point-mobile {
  text-decoration: none;
  @include font($weight: normal, $color: $black);
  white-space: nowrap;
}
