@import '../variables';
@import '../mixins';

.row {
  margin: 10px 0;
  height: 30px;
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
}
.name {
  @include font($weight: bold, $color: $primary);
}
.inp {
  margin-left: 10px;
  width: stretch;
}
.date-input {
  margin: 0 15px;
  align-items: center;
  width: 60%;
}

.btn-container {
  margin: 20px 0;
  @include displayFlex($display: flex, $justify-content: center);
}

.error-text {
  color: $secondary;
}

.subtitle {
  min-height: 30px;
  margin: 10px 0;
  @include displayFlex($display: flex, $direction: row, $align-items: center);
}

.text {
  margin-left: 20px;
  overflow-wrap: anywhere;
}

.min-width {
  min-width: 30%;
}

.delete-icon {
  @include size($width: 25px, $height: 25px);
  color: $blue;
  margin-top: 5px;
  cursor: pointer;
}

.input-wrapper {
  position: relative;
  width: 70%;
}
