@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex(
    $display: flex,
    $direction: row,
    $align-items: center,
    $justify-content: flex-start,
    $wrap: wrap
  );
  min-height: 50px;
  gap: 16px;
  padding: 8px 0;
}

.title {
  @include font($weight: bold, $text-transform: uppercase);
}

.line {
  margin: 0;
  height: 1px;
  background-color: $grey;
}
