@import '../variables';
@import '../mixins';

.wrapper {
  margin: 12px 14px 24px 14px;
  @include displayFlex($display: flex, $align-items: start, $direction: row);
}

.info {
  &-container {
    @include displayFlex($display: flex, $align-items: center, $direction: row);
    margin: 8px 0;
  }
  &-icon {
    @include size($width: 21px, $height: 21px);
    margin: 8px 8px 0 0;
    color: #1941a3;
  }
  &-image {
    @include size($width: 21px, $height: 21px);
    margin: 0 5px 0px 0;
  }
  &-description {
    @include font($size: 15px, $weight: 400);
    color: #1941a3;
  }
  &-description-right {
    @include font($size: 15px, $weight: 400);
    color: #1941a3;
    padding-left: 4px;
  }
  &-red {
    @include size($width: 21px, $height: 21px);
    margin: 0 5px 0px 0;
    path {
      fill: #d24444;
    }
  }
}

.indented {
  margin-left: 10px;
}

.link {
  &-icon {
    @include size($width: 20px, $height: 20px);
    color: $blue;
    margin: 0 5px 0 0;
    stroke-width: 0.8;
  }
}
