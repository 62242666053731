@import '../variables';
@import '../mixins';

.wrapper {
  padding: 15px;
  border-radius: 10px;
  margin: 20px 0;
  overflow-wrap: anywhere;
}

.edge {
  box-shadow: $shadow;
}

.image {
  @include displayFlex($display: flex, $justify-content: center, $align-items: center);
  @include size($width: 100px, $height: 100px);
  object-fit: contain;
  border: 1px solid $border-grey;
  border-radius: 10px;
  padding: 2px;
  position: relative;

  &-wrapper {
    position: relative;
  }
  &-row {
    @include displayFlex($display: flex, $direction: row, $align-items: center);
    cursor: pointer;
  }
  &-icon {
    @include size($width: stretch, $height: auto);
    padding: 25px;
    background-color: $dark-grey;
    color: $white;
    border-radius: 10px;
    cursor: pointer;
  }
}

.edit-icon {
  @include size($width: 20px, $height: 20px);
  @include position($position: absolute, $bottom: -5px, $right: -5px);
  color: $white;
  padding: 10px;
  border-radius: 50%;
  background-color: $grey;
}

.title {
  @include font($weight: bold, $size: 18px);
}
.type {
  @include font($weight: bold, $color: $primary);
}
.lighter {
  opacity: 0.6;
  padding-right: 8px;
}
.description-container {
  @include displayFlex($display: flex, $direction: column, $justify-content: space-between);
  @include size($min-width: 100px, $min-height: 100px);
  margin-left: 15px;
}
.contact {
  @include size($width: 22px, $height: 22px);
  color: $blue;
  cursor: pointer;
  &-circle {
    @include size($width: 25px, $height: 25px);
    @include displayFlex($display: flex, $direction: column, $align-items: center, $justify-content: center);
    padding: 3px;
    border: 1px solid $blue;
    border-radius: 50%;
  }
}
.icon-container {
  @include displayFlex($display: flex, $direction: row, $justify-content: flex-end, $align-items: center);
  position: relative;
}
.line {
  margin: 12px 0 10px 0;
  border-bottom: 1px solid $grey;
  width: 100%;
}
.red-icon {
  color: $secondary;
  margin-right: 12px;
  margin-left: 1px;
}
.logo {
  @include size($width: 20px, $height: 20px);
  color: $dark-grey;
  margin-right: 10px;
}
.notification-icon {
  @include size($width: 20px, $height: 20px);
  color: $dark-grey;
  margin: 0 10px;
}
.link-icon {
  @include size($width: 20px, $height: 20px);
  color: $blue;
  margin: 0 10px;
  stroke-width: 0.8;
}
.separator {
  color: $border-grey;
  width: 1px;
  height: 100%;
  margin-left: 5px;
}
.project {
  opacity: 0.6;
}
.space-between {
  @include displayFlex($display: flex, $direction: row, $justify-content: space-between, $align-items: center);
  cursor: pointer;
}

.remove-bottom-margin {
  margin-bottom: 0 !important;
}

.remove-margin {
  margin: 0 !important;
}

.red-text {
  @include font($weight: bold, $color: $secondary);
}

.text-separator {
  @include font($weight: bold, $color: $grey);
  padding: 0 6px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 4px;
}

.card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  position: relative;
  text-decoration: none;
}

.service-done-icon-style {
  position: absolute;
  width: 24px;
  height: 24px;
  color: $blue;
  z-index: 1;
}

.ban-icon-style {
  position: absolute;
  color: red;
  width: 24px;
  height: 24px;
  z-index: 2;
}

.service-done-icon-style-wrapper {
  min-width: 24px;
  min-height: 24px;
  padding-right: 8px;
  position: relative;
}

.bottom-info-container {
  @include displayFlex($display: flex, $direction: row, $justify-content: space-between, $align-items: center);

  div {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;

    &:last-child {
      text-align: right;
    }
  }
}
