@import '../variables';
@import '../mixins';

.attribute {
  width: 80px;
  &-primary {
    color: $primary;
  }
  &-indented {
    margin-left: 15px;
    width: 80px;
  }
}

.row {
  margin: 15px 0;
  @include displayFlex($display: flex, $direction: row, $align-items: center);

  &-center {
    margin: 30px 0 10px 0;
    @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: center);
  }
}

.note {
  @include size($width: stretch, $height: auto);
  border: 1px solid $border-grey;
  border-radius: 10px;
  padding: 10px;
  min-height: 20px;
}

.text {
  min-width: 25px;
}

.line {
  margin: 0;
  height: 1px;
  background-color: $grey;
}

.bolded {
  font-weight: bold;
}

.placeholder {
  color: $grey;
}
