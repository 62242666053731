@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: row, $justify-content: center, $align-items: center);
  width: 20px;
  height: 10px;
  margin-top: 10px;

  .text {
    width: fit-content;
    height: 10px;
    color: white;
    margin-right: 8px;
  }
}

.custom {
  * {
    box-shadow: none !important;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 20px 0;
}

.active {
  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media only screen and (min-width: 768px) {
      padding: 15px 0 15px 15px;
    }

    @media only screen and (max-width: 768px) {
      padding: 15px 0 15px 15px;
    }
  }

  &-text {
    padding-right: 8px;
  }
}
