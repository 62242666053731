@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: column, $justify-content: center, $align-items: center, $wrap: wrap);
  gap: 6px;
  margin: 50px 0;
  .sign {
    margin-left: 6px;
    font-weight: bold;
    cursor: pointer;
    border-bottom: 1px solid $grey;
  }
}
