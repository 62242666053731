@import '../variables';
@import '../mixins';

.row {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
  margin: 20px 0;
}
.inp-container {
  width: 200px;
}
.inp {
  margin: 0 !important;
}

.text {
  min-width: 100px;
  text-align: center;
}
.line {
  width: 2px;
  height: 20px;
  background-color: $dark-grey;
  margin: 0 10px;
}
.gold {
  color: $primary;
  min-width: 0px;
  margin-left: 20px;
  margin-right: 5px;
}
.no-space {
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: flex-start);
}
.input {
  width: 90%;
}
