@import '../variables';
@import '../mixins';

.row {
  @include displayFlex($display: flex, $direction: row, $align-items: center);
  .title {
    @include font($color: $primary, $text-transform: uppercase, $weight: bold);
    @include displayFlex($display: flex, $justify-content: center);
    transition: color 0.5s ease;
    width: 100%;
    cursor: pointer;

    &-selected {
      color: $primary;
    }

    &-unselected {
      color: #aaaaaa;
    }

    &-small {
      display: block;
      width: 33%;
      word-wrap: break-word;
      text-align: center;
    }
  }
  .line {
    @include size($width: 100%, $height: 1px);
    margin: 10px 0;
    transition: background-color 0.5s ease-in-out;

    &-selected {
      background-color: $primary;
    }

    &-unselected {
      background-color: $border-grey;
    }
  }
  .content {
    transition: all 1s ease-in-out;
  }
}
