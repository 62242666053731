@import 'extends';
@import 'variables';
@import 'mixins';

body {
  font-family: $font-family;
  margin: 0;
}

#root {
  @include displayFlex($display: flex, $direction: column);
  @include size($height: 100%, $min-height: 100vh);
}
p {
  margin: 0;
}

hr {
  border-color: $border-grey;
}

input[type='date'][value='']:not(:focus) {
  opacity: 0.5;
}

input[type='date'][value=''] {
  opacity: 0.5;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

main {
  -webkit-box-flex: 1 0 auto;
  -moz-box-flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.small {
  @extend .general-small-padding;
}

.last-element {
  padding-top: 50px;
}

.react-date-picker__wrapper {
  border: 0 !important;
  width: 100% !important;
  flex: 1 !important;
  flex-direction: row-reverse !important;
}

.react-date-picker__inputGroup {
  min-width: 60% !important;
  height: 28px;
  text-align: center;
  border-radius: 15px;
  border: 1px solid $border-grey;
  outline: 0;
  font-size: 16px;
  margin: 0 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: none;
}

.react-date-picker__calendar-button {
  margin-right: 16px;
  margin-left: 5px;
}
