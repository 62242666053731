@import '../variables';
@import '../mixins';

.wrapper {
  height: 50px;
  @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: space-between);
}

.title {
  @include font($text-transform: uppercase, $color: $blue);
}

.line {
  margin: 0;
  height: 1px;
  background-color: $grey;
}

.link {
  cursor: pointer;
}
