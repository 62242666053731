@import '../variables';
@import '../mixins';

.wrapper {
  width: 100%;

  .description {
    @include displayFlex($display: flex, $direction: column, $justify-content: center, $align-items: space-between);
    width: stretch;
    margin: 5px 0 10px 0;
  }
  .title {
    display: table;
    @include font($weight: bold, $color: $primary);
  }
  .date {
    @include font($weight: bold, $color: $blue, $size: 13px);
  }
  .row {
    margin: 10px 0;
    @include displayFlex($display: flex, $direction: row, $align-items: center);
  }
  .box {
    border-radius: 10px;
    padding: 10px;
    background-color: $grey;
    cursor: pointer;
  }
  .icon {
    border-radius: 20px;
    color: $blue;
    padding: 5px;
    @include size($width: 20px, $height: 20px);
    background-color: $white;
  }
  .text {
    background-color: $white;
    min-width: 50px;
    text-align: center;
  }
  .instrument-category {
    margin-left: 5px;
    color: #000000;
    opacity: 0.6;
    font-weight: 400;
  }
  .no-margin {
    margin: 0;
  }
}

.date-separator {
  padding: 0 6px;
  font-size: 13px;
  font-weight: bold;
}

.red-text {
  color: $secondary;
  font-size: 13px;
  font-weight: bold;
}

.date-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.date {
  &-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
    margin-bottom: 8px;
    margin-top: 16px;
    margin-left: 4px;
  }
  &-text {
    @include font($weight: normal);
  }
  &-text-bold {
    @include font($weight: bold);
  }
  &-icon {
    margin: 0 8px;
    @include size($width: 22px, $height: 22px);
  }
}

.image {
  @include displayFlex($display: flex, $justify-content: center, $align-items: center);
  @include size($width: 100px, $height: 100px);
  object-fit: contain;
  border: 1px solid $border-grey;
  border-radius: 10px;
  padding: 2px;
  position: relative;

  &-wrapper {
    position: relative;
  }
  &-row {
    @include displayFlex($display: flex, $direction: row, $align-items: center);
    cursor: pointer;
  }
  &-icon {
    @include size($width: stretch, $height: auto);
    padding: 25px;
    background-color: $dark-grey;
    color: $white;
    border-radius: 10px;
    cursor: pointer;
  }
}
