@import '../variables';
@import '../mixins';

.wrapper {
  @include displayFlex($display: flex, $direction: row);
  width: 100%;
  overflow-wrap: anywhere;

  .bubble {
    @include size($width: 40px, $height: 40px);
    border-radius: 50%;
    background-color: $dark-grey;
    margin: 5px 2px 1px 5px;
    &-container {
      @include displayFlex($display: flex, $direction: column, $align-items: center);
      margin-right: 10px;
    }
    &-out {
      @include size($width: 50px, $height: 50px);
      border-radius: 50%;
      background-color: $border-grey;
    }
  }

  .right {
    width: 100%;
  }
  .row {
    @include displayFlex($display: flex, $direction: row);
  }
  .description {
    @include displayFlex($display: flex, $direction: column, $justify-content: center, $align-items: space-between);
    width: 100%;
    margin: 5px 0 10px 0;
  }
  .title {
    display: table;
    color: $primary;
    font-weight: bold;
  }
  .sub-title {
    margin-top: 4px;
    margin-bottom: 10px;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: $border-grey;
  }
  .long-line {
    background-color: $border-grey;
    width: 1px;
    height: 100%;
    margin: 0;
    flex: 2;
  }
  .short-line {
    background-color: $border-grey;
    width: 1px;
    height: 5px;
    margin: 0;
  }
  .box {
    @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: center);
    border-radius: 10px;
    padding: 10px 10px;
    background-color: $border-grey;
    cursor: pointer;
  }
  .grey-text {
    opacity: 0.6;
    padding-left: 5px;
    white-space: nowrap;
  }
  .green-text {
    color: $blue;
    padding: 0 5px;
    white-space: nowrap;
  }
  .instrument-category {
    margin-left: 5px;
    color: #000000;
    opacity: 0.6;
    font-weight: 400;
  }
}
